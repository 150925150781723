


const Data = [

    {
        lat : 22.272008,
        lon : 73.190922,
        area : "Manjalpur",
        name : "puncture1"
    },

    {
        
        lat : 22.269603,
        lon : 73.198074,
        area : "Manjalpur",
        name : "puncture2"
    },
];

export default Data;